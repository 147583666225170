'use strict';

// Adicionar classe para ocultar o carrossel durante a inicialização
$('.mainCarouselBanner').addClass('hide-carousel');

const interval = setInterval(function () {
  if (document.getElementsByClassName('mainCarouselBanner')) {
    $('.mainCarouselBanner').slick({
      dots: true,
      infinite: true,
      autoplay: true,
      speed: 500,
      slidesToShow: 1,
      prevArrow: '<span class="slick-prev"></span>',
      nextArrow: '<span class="slick-next"></span>',
      responsive: [
        {
          breakpoint: 991,
          settings: {
            dots: true,
            prevArrow: false,
            nextArrow: false
          }
        }
      ]
    });

    $('.mainCarouselBanner').on('setPosition', function(event, slick) {
      $('.mainCarouselBanner').removeClass('hide-carousel');
      $('.mainCarouselLoader').addClass('d-none');
    });

    clearInterval(interval);
  }
}, 100);
